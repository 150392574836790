/* eslint-env browser */
/* eslint-disable max-classes-per-file */

import {DateTime} from "luxon";
import queryString from "qs";

class Report {
  constructor($injector, report, type, study) {
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this._backendConfig = $injector.get("backendConfig");
    this._Session = $injector.get("Session");
    this.features = $injector.get("Config").features;
    Object.assign(this, report);
    this.type = type;

    if (!this.tzSerial) {
      this.studyId = study.id;
      this.studyStartDate = study.studyStartDate;
      this.studyEndDate = study.studyEndDate;
      this.studyIndication = study.studyIndication;
      this.studyNotes = study.studyNotes;
      this.studyState = study.studyState;
      this.studyType = study.studyType;
    }

    // Valid timestamps are 2010 or later
    this.validTimestamp = DateTime.fromISO(this.timestamp).year >= 2010;
  }

  get locked() {
    // Item is locked if there is a lockedAt timestamp
    let locked = !!this.lockedAt;
    // If the item is locked by you, display it as unlocked.
    if (
      locked &&
      this.lockedBy === this._Authentication.getUserId() &&
      (this.sessionId === null || this.sessionId === this._Session.sessionId)
    ) {
      locked = false;
    }
    return locked;
  }

  get assignedToOtherUser() {
    if (!this.features.itemAssignments || this.assignedUsers?.length === 0) {
      return false;
    }

    const currentUserId = this._Authentication.getUserId();
    const currentUserIsAssigned = this.assignedUserIds.includes(currentUserId);

    return !currentUserIsAssigned;
  }

  get isUnopenable() {
    return this.locked || this.assignedToOtherUser;
  }

  get assignedUserIds() {
    return this.assignedUsers?.map((user) => user.userId) || [];
  }

  get assignedUserNames() {
    return this.assignedUsers?.map((user) => user.userFullName) || [];
  }

  /*
   * @see SRS: BR-1569
   */
  httpPatch(url, data) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.patch(`${baseUrl}${url}`, data, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpGet(url, query = {}) {
    const urlQuery = queryString.stringify(query);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  /**
   * Re-attempts DELETE request with exponential backoff.
   *
   * Default behavior:
   *
   * Attempt 1 fails
   *    <Delay: 500ms>
   * Attempt 2 fails
   *    <Delay: 1000ms>
   * Attempt 3 fails
   *    <Delay: 2000ms>
   * Attempt 4 fails
   *    <Delay: 4000ms>
   * Attempt 5 fails
   * Then an error is thrown
   *
   * @param {String} url
   * @param {Number} retriesRemaining
   * @param {Number} delayMs
   * @returns {Promise<Object>}
   */
  async _httpDeleteWithRetries(url, retriesRemaining = 4, delayMs = 500) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;

    let result;
    try {
      result = await this._$http.delete(`${baseUrl}${url}`, {
        headers: {
          Authorization: authHeader,
        },
      });
    } catch (err) {
      if (retriesRemaining <= 0) {
        // If we're out of retries, just rethrow the error.
        throw err;
      }
      if (!(Number.isInteger(err.status) && err.status >= 500)) {
        // If the error is not a 500, just rethrow the error.
        throw err;
      }

      // Wait a short delay
      await new Promise((r) => {
        setTimeout(r, delayMs);
      });

      // Retry
      return this._httpDeleteWithRetries(url, retriesRemaining - 1, delayMs * 2);
    }

    return result;
  }
}

class DailyTrend extends Report {
  constructor($injector, report, study) {
    super($injector, report, "Daily Trend", study);
  }

  /*
   * @see SRS: BR-1569
   */
  complete() {
    return this.httpPatch(`/reports/dailyTrend/${this.id}`, {completed: true}).then((response) => {
      if (response.status === 200) {
        this.completed = true;
      } else {
        console.error("Daily Trend could not be completed");
      }
    });
  }
}

class Summary extends Report {
  constructor($injector, report, study) {
    super($injector, report, "Summary", study);
  }

  /*
   * @see SRS: BR-3185
   */
  complete() {
    return this.httpPatch(`/reports/summary/${this.id}`, {completed: true}).then((response) => {
      if (response.status === 200) {
        this.completed = true;
      } else {
        console.error("Summary item could not be completed");
      }
    });
  }
}

/* @ngInject */
export default class ReportService {
  constructor($injector) {
    this._$injector = $injector;
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this._backendConfig = $injector.get("backendConfig");
    this._features = this._backendConfig.features;

    this._supportedReportTypes = ["Daily Trend", "Summary"];
  }

  getArrhythmiaEventsData(studyId, startTime, endTime) {
    startTime = new Date(startTime).toISOString();
    endTime = new Date(endTime).toISOString();

    const url = `/reports/arrhythmiaTimeline/${studyId}`;
    const query = {startTime, endTime};

    return this._httpGet(url, query).then((response) => {
      return response.data;
    });
  }

  getVentricularEctopy(studyId, startTime, endTime) {
    startTime = new Date(startTime).toISOString();
    endTime = new Date(endTime).toISOString();

    const url = `/reports/ventricularEctopy/${studyId}`;
    const query = {startTime, endTime};

    return this._httpGet(url, query).then((response) => {
      return response.data;
    });
  }

  getHeartRateTrend(isSummary, studyId, startTime, endTime) {
    startTime = new Date(startTime).toISOString();
    endTime = new Date(endTime).toISOString();

    const url = `/reports/heartRateTrend/${isSummary ? "summary" : "dailyTrend"}/${studyId}`;
    const query = {startTime};
    if (isSummary) {
      query.endTime = endTime;
    }

    return this._httpGet(url, query).then((response) => {
      return response.data;
    });
  }

  getPvcBurden(studyId, startTime, endTime) {
    const url = `/reports/pvcBurden/${studyId}`;
    const query = {startTime, endTime};

    return this._httpGet(url, query).then((response) => {
      return response.data;
    });
  }

  getReportTypes() {
    return this._supportedReportTypes;
  }

  // used for handling results of GET /inboxItems
  instantiateReport(report) {
    let reportInstance = {};
    switch (report.type) {
      case "Daily Trend":
        reportInstance = new DailyTrend(this._$injector, report);
        break;
      case "Summary":
        reportInstance = new Summary(this._$injector, report);
        break;
      default:
        throw new Error(`Report could not be created, unsupported report type: ${report.type}`);
    }

    return reportInstance;
  }

  updateReport(report, updatedProperties) {
    const url =
      report.type === "Summary" ? `/reports/summary/${report.id}` : `/reports/dailyTrend/${report.id}`;

    return this._httpPatch(url, updatedProperties);
  }

  _httpGet(url, query = {}) {
    const urlQuery = queryString.stringify(query);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpPatch(url, data) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.patch(`${baseUrl}${url}`, data, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
